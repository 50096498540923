<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3334 21.3334H2.66666V2.66666H12V0H0V24H24V12H21.3334V21.3334ZM14.6667 0V2.66666H19.4533L6.34666 15.7733L8.22666 17.6533L21.3334 4.54666V9.33334H24V0H14.6667Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcOpenNewWindow',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
